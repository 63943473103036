import React, { useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Text,
  Title,
  Table,
  IconButton,
  Icon,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import axiosInstance from '../../axios';
import { CogIcon, ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import { useWindowWidth } from '../../hooks';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { useHistory } from 'react-router-dom';

const LogsPage: React.FC = () => {
  const { push } = useHistory();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [currentSeller, setCurrentSeller] = useState<Seller | null>();
  const [orders, setOrdersLogs] = useState([]);
  const [clients, setClientsLogs] = useState([]);
  const [products, setProductsLogs] = useState([]);
  const [sellersLogs, setSellersLogs] = useState<SellerLogs[]>([]);
  const [isviewSellers, setViewSellers] = useState(true);
  const [isviewProducts, setViewProducts] = useState(false);
  const [isviewOrders, setViewOrders] = useState(false);
  const [isviewClients, setViewClients] = useState(false);
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    getLogs(null);
    getSellersList();
  }, []);

  const goToSetup = () => push('/setup');

  interface Product {
    id: number;
    sku: string;
    type: string;
    tn_id: string | null;
    marketplace_tn_id: string | null;
    message: string | null;
    created_at: string | null;
  }

  interface Client {
    id: number;
    customer_identification: string;
    type: string;
    customer_name: string;
    tn_id: string | null;
    message: string | null;
    created_at: string | null;
  }

  interface Order {
    id: number;
    tn_order_number: number;
    type: string;
    customer_identification: string;
    message: string | null;
    created_at: string | null;
  }

  interface SellerLogs {
    id: number;
    store_id: number;
    type: string;
    message: string | null;
    created_at: string | null;
  }

  interface Seller {
    store_id: number;
    nombre_tienda: string;
    url: string;
  }

  const getSellersList = async () => {
    console.log('get sellers list');

    await axiosInstance
      .get('/approved_sellers_list')
      .then((response) => {
        setSellers(response?.data?.sellers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeSellerSelect = async (e: any) => {
    for await (const seller of sellers) {
      if (seller.store_id == e.target.value) {
        console.log('seller found');
        const currentSeller: Seller = {
          store_id: seller.store_id,
          nombre_tienda: seller.nombre_tienda,
          url: seller.url,
        };
        setCurrentSeller(currentSeller);
        getLogs(currentSeller?.store_id);
        break;
      }
    }
  };

  const getLogs = async (store_id: number | null) => {
    console.log('get logs call');

    if (store_id == null) {
      setCurrentSeller(null);
    }
    await axiosInstance
      .get('/logs', {
        params: {
          filter: store_id,
        },
      })
      .then((logs) => {
        if (logs.data.p_logs) {
          setProductsLogs(logs.data.p_logs);
        }
        if (logs.data.c_logs) {
          setClientsLogs(logs.data.c_logs);
        }
        if (logs.data.o_logs) {
          setOrdersLogs(logs.data.o_logs);
        }
        if (logs.data.s_logs) {
          setSellersLogs(logs.data.s_logs);
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        if (e.response?.data?.error_code == 'APP_VALIDATION_PENDING') {
          goToSetup();
        }
      });

    return;
  };

  const viewProducts = async () => {
    console.log('view products ');
    setViewOrders(false);
    setViewClients(false);
    setViewProducts(true);
    setViewSellers(false);
  };

  const viewOrders = async () => {
    console.log('view orders ');
    setViewOrders(true);
    setViewClients(false);
    setViewProducts(false);
    setViewSellers(false);
  };

  const viewSellers = async () => {
    console.log('view sellers ');
    setViewOrders(false);
    setViewClients(false);
    setViewProducts(false);
    setViewSellers(true);
  };

  /*
  const viewClients = async () => {
    console.log('view clients ');
    setViewOrders(false);
    setViewClients(true);
    setViewProducts(false);
    setViewSellers(false);
  };
  */

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={viewSellers}
        source={<CogIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Box paddingRight="10">
          <Button appearance="primary" onClick={() => getLogs(null)}>
            Refrescar
          </Button>
        </Box>
        <Button appearance="primary" onClick={viewSellers}>
          <Icon source={<CogIcon />} color="currentColor" />
          Sellers
        </Button>
        <Button appearance="primary" onClick={viewProducts}>
          <Icon source={<CogIcon />} color="currentColor" />
          Productos
        </Button>
        <Button appearance="primary" onClick={viewOrders}>
          <Icon source={<CogIcon />} color="currentColor" />
          Ordenes
        </Button>
        {/* 
        <Button appearance="primary" onClick={viewClients}>
          <Icon source={<CogIcon />} color="currentColor" />
          Clientes
        </Button>
        */}
      </Box>
    );

  return (
    <Page maxWidth="1200px">
      <Page.Header title="Registros" buttonStack={actions} />
      <Page.Body>
        <Card>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            marginBottom="6"
          >
            <Text>Seleccionar Seller</Text>
            <Select
              appearance="neutral"
              id="seller-list-select"
              name="Name"
              onChange={(event) => {
                handleChangeSellerSelect(event);
              }}
            >
              <Select.Option
                disabled
                label="Seleccionar Seller"
                selected={currentSeller == null}
                value="Seleccionar Seller"
              />
              {sellers ? (
                sellers.map((seller: Seller) => (
                  <Select.Option
                    key={seller.store_id}
                    value={String(seller.store_id)}
                    label={seller.nombre_tienda}
                  ></Select.Option>
                ))
              ) : (
                <Select.Skeleton />
              )}
            </Select>
          </Box>
        </Card>
        {isviewSellers ? (
          <Card>
            <Card.Header title="Logs sellers" />
            <Card.Body>
              <Table>
                <React.Fragment key=".0">
                  <Table.Head>
                    <Table.Row backgroundColor="neutral-surface">
                      <Table.Cell as="th" width="50px">
                        STORE ID
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        TIPO
                      </Table.Cell>
                      <Table.Cell as="th" width="180px">
                        MENSAJE
                      </Table.Cell>
                      <Table.Cell as="th" width="150px">
                        FECHA CREACIÓN
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {sellersLogs ? (
                      sellersLogs.map((seller: SellerLogs) => (
                        <Table.Row key={seller.id}>
                          <Table.Cell>{seller.store_id}</Table.Cell>
                          <Table.Cell>{seller.type}</Table.Cell>
                          <Table.Cell>{seller.message}</Table.Cell>
                          <Table.Cell>{seller.created_at}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row> </Table.Row>
                    )}
                  </Table.Body>
                </React.Fragment>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <div></div>
        )}
        {isviewProducts ? (
          <Card>
            <Card.Header title="Logs productos" />
            <Card.Body>
              <Table>
                <React.Fragment key=".0">
                  <Table.Head>
                    <Table.Row backgroundColor="neutral-surface">
                      <Table.Cell as="th" width="50px">
                        SKU
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        TIPO
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        SELLER ID
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        MARKETPLACE ID
                      </Table.Cell>
                      <Table.Cell as="th" width="180px">
                        MENSAJE
                      </Table.Cell>
                      <Table.Cell as="th" width="150px">
                        FECHA CREACIÓN
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {products ? (
                      products.map((product: Product) => (
                        <Table.Row key={product.id}>
                          <Table.Cell>{product.sku}</Table.Cell>
                          <Table.Cell>{product.type}</Table.Cell>
                          <Table.Cell>{product.tn_id}</Table.Cell>
                          <Table.Cell>{product.marketplace_tn_id}</Table.Cell>
                          <Table.Cell>{product.message}</Table.Cell>
                          <Table.Cell>{product.created_at}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row> </Table.Row>
                    )}
                  </Table.Body>
                </React.Fragment>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <div></div>
        )}
        {isviewClients ? (
          <Card>
            <Card.Header title="Logs clientes" />
            <Card.Body>
              <Table>
                <React.Fragment key=".0">
                  <Table.Head>
                    <Table.Row backgroundColor="neutral-surface">
                      <Table.Cell as="th" width="80px">
                        IDENTIFICACIÓN
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        TIPO
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        NOMBRE
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        TN ID
                      </Table.Cell>
                      <Table.Cell as="th" width="180px">
                        MENSAJE
                      </Table.Cell>
                      <Table.Cell as="th" width="150px">
                        FECHA CREACIÓN
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {clients ? (
                      clients.map((client: Client) => (
                        <Table.Row key={client.id}>
                          <Table.Cell>
                            {client.customer_identification}
                          </Table.Cell>
                          <Table.Cell>{client.type}</Table.Cell>
                          <Table.Cell>{client.customer_name}</Table.Cell>
                          <Table.Cell>{client.tn_id}</Table.Cell>
                          <Table.Cell>{client.message}</Table.Cell>
                          <Table.Cell>{client.created_at}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row> </Table.Row>
                    )}
                  </Table.Body>
                </React.Fragment>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <div></div>
        )}
        {isviewOrders ? (
          <Card>
            <Card.Header title="Logs ordenes" />
            <Card.Body>
              <Table>
                <React.Fragment key=".0">
                  <Table.Head>
                    <Table.Row backgroundColor="neutral-surface">
                      <Table.Cell as="th" width="80px">
                        NRO ORDEN
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        TIPO
                      </Table.Cell>
                      <Table.Cell as="th" width="100px">
                        IDENTIFICACION CLI
                      </Table.Cell>
                      <Table.Cell as="th" width="180px">
                        MENSAJE
                      </Table.Cell>
                      <Table.Cell as="th" width="150px">
                        FECHA CREACIÓN
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {clients ? (
                      orders.map((order: Order) => (
                        <Table.Row key={order.id}>
                          <Table.Cell>{order.tn_order_number}</Table.Cell>
                          <Table.Cell>{order.type}</Table.Cell>
                          <Table.Cell>
                            {order.customer_identification}
                          </Table.Cell>
                          <Table.Cell>{order.message}</Table.Cell>
                          <Table.Cell>{order.created_at}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row> </Table.Row>
                    )}
                  </Table.Body>
                </React.Fragment>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <div></div>
        )}
      </Page.Body>
    </Page>
  );
};

export default LogsPage;
