import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';
import axiosInstance from '../../axios';

const headersPendientes = [
  'Id',
  'Nombre Tienda',
  'Url',
  'Fecha suscripción',
  'Seller Tag',
  'Acciones',
];
const headersAprobados = [
  'Id',
  'Nombre Tienda',
  'Url',
  'Fecha aprobado',
  'Seller Tag',
  'Acciones',
];

const SetupPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVinculate, setIsVinculate] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    usuario_api: '',
    access_key: '',
  });
  const [pendingSellers, setPendingSellers] = useState<Seller[]>([]);
  const [approvedSellers, setApprovedSellers] = useState<Seller[]>([]);

  interface Seller {
    store_id: string;
    nombre_tienda: string;
    origen_producto: string;
    tipo_oferta: string;
    registro_marca: string;
    nombre: string;
    telefono: string;
    email: string;
    url_tienda: string;
    url: string;
    seller_tag: string;
    status: string;
    subscribed_at: string | null;
    approved_at: string | null;
  }
  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    //checkVinculateAccount();
    getListOfPendingSellers();
    getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleGoToRejected = async () => {
    push('/rejected');
  };

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  /*
  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();
  */

  const getListOfPendingSellers = async () => {
    console.log('obteniendo sellers pendientes...');
    try {
      const response = await axiosInstance.get('/pending_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setPendingSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const getListOfSellers = async () => {
    console.log('obteniendo sellers aprobados...');
    try {
      const response = await axiosInstance.get('/approved_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setApprovedSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const handleFilterPendientes = async (e: any) => {
    console.log(e.target.value);
    try {
      const response = await axiosInstance.get('/pending_sellers_filter', {
        params: {
          filter: e.target.value,
        },
      });
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setPendingSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const handleFilterAprobados = async (e: any) => {
    console.log(e.target.value);
    try {
      const response = await axiosInstance.get('/approved_sellers_filter', {
        params: {
          filter: e.target.value,
        },
      });
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setApprovedSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const handleSellerStatusChange = (e: any) => {
    console.log(e.target.value, ' - ', e.target.key, ' - ', e.target.label);
    console.log(e.target.id);
    console.log(e.key);
  };

  const approveSeller = async (store_id: any) => {
    console.log('approve seller store id : ' + store_id);
    const seller = pendingSellers.find((s) => s.store_id == store_id);
    if (!seller || seller.seller_tag == '' || seller.seller_tag == null) {
      addToast({
        id: 'approve-seller-tag-fail',
        type: 'danger',
        text: 'El seller debe tener un tag para ser aprobado',
        duration: 4000,
      });
      return;
    }
    if (seller?.seller_tag.includes(' ')) {
      addToast({
        id: 'approve-seller-tag-fail-2',
        type: 'danger',
        text: 'El tag no pude contener espacios',
        duration: 4000,
      });
      return;
    }

    await axiosInstance
      .post('/approve_seller', {
        store_id: store_id,
        seller_tag: seller.seller_tag,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status == true) {
          const seller = pendingSellers.find((s) => s.store_id == store_id);
          console.log('seller found to remove ? ', seller);
          setPendingSellers(
            pendingSellers.filter((seller) => {
              console.log(
                'seller store id dif?? ' + (seller.store_id !== store_id),
              );
              return seller.store_id !== store_id;
            }),
          );
          if (seller) setApprovedSellers([...approvedSellers, seller]);

          addToast({
            id: 'approve-seller-success',
            type: 'success',
            text: 'Seller aprobado!',
            duration: 4000,
          });
        }
      })
      .catch((e) => {
        addToast({
          id: 'approve-seller-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error al tratar de aprobar el seller',
          duration: 4000,
        });
        console.log(e);
      });
    return;
  };

  const rejectSeller = async (store_id: any) => {
    console.log('reject seller store id : ' + store_id);
    const seller = pendingSellers.find((s) => s.store_id == store_id);

    await axiosInstance
      .post('/reject_seller', {
        store_id: store_id,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status == true) {
          const seller = pendingSellers.find((s) => s.store_id == store_id);
          console.log('seller found to remove ? ', seller);
          setPendingSellers(
            pendingSellers.filter((seller) => {
              return seller.store_id !== store_id;
            }),
          );
          addToast({
            id: 'reject-seller-success',
            type: 'success',
            text: 'Seller rechazado',
            duration: 4000,
          });
        }
      })
      .catch((e) => {
        addToast({
          id: 'reject-seller-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error al tratar de rechazar el seller',
          duration: 4000,
        });
        console.log(e);
      });
    return;
  };

  const viewSellerProducts = async (store_id: any) => {
    console.log('view seller products store id : ' + store_id);
    //push('/config', { data: 'asd' });

    //push('/config?asd=8', 'asd');

    //history.pushState('asd', '', '/config');

    //push('/config', { 'asd ': 'as2d' });

    push({
      pathname: '/config?' + store_id,
      state: { data: store_id },
    });

    //return;
  };

  const viewSellerData = async (store_id: any) => {
    console.log('view seller data store ' + store_id);
    /*
    push({
      pathname: '/data?asd=333',
      search: '?asd=888',
      state: { data: 'asd' },
    });
    */
    push({
      pathname: '/data?' + store_id,
      state: { data: store_id },
    });
  };

  const viewSellerMetrics = async (store_id: any) => {
    console.log('view seller metrics store id : ' + store_id);
    /*
    push({
      pathname: '/metrics',
      search: '?asd=888',
      state: { data: 'asd' },
    });
    */
    push({
      pathname: '/metrics?' + store_id,
      state: { data: store_id },
    });
  };

  /*
  const checkVinculateAccount = async () => {
    console.log('checkeand vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        setIsVerifing(false);
        setIsVinculate(true);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
    }
    return;
  };
  */

  /*
  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };
  */

  const handleChangeSellerTag = (e: any) => {
    console.log(e.target.value, ' - ', e.target.id);
    const seller = pendingSellers.find((s) => s.store_id == e.target.id);
    if (seller) seller.seller_tag = e.target.value;
  };

  const actions = (
    <Box display="flex" gap="4">
      <Button onClick={handleGoToRejected} appearance="danger">
        Ver rechazados/desinstalados
      </Button>
    </Box>
  );

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  return (
    <Page maxWidth="1500px" marginTop="18">
      {!isVerifing && isVinculate ? (
        <>
          <Page.Header
            alignItems="center"
            title="Tu cuenta ya está vinculada y activa"
          />
          <Box display="flex" gap="4" justifyContent="center">
            <Button appearance="primary" onClick={handleGoToConfig}>
              <Icon source={<CogIcon />} color="currentColor" />
              Ir a configuración
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Page.Header title="Sellers" buttonStack={actions} />
          <Page.Body>
            <Layout columns="1">
              <Layout.Section>
                <Box display="flex" flexDirection="column" gap="4" padding="4">
                  <Title textAlign="center" as="h3">
                    Lista de sellers pendientes
                  </Title>
                  <Box display="inline-flex" gap="2" maxWidth="200px">
                    <Text as="span">Filtrar: </Text>
                    <Input
                      name="filtro_pendientes"
                      type="text"
                      placeholder=""
                      onChange={(event) => {
                        handleFilterPendientes(event);
                      }}
                    />
                  </Box>
                </Box>
                <Table>
                  <Table.Head>
                    {headersPendientes.map((header) => (
                      <Table.Cell key={header}>{header}</Table.Cell>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {pendingSellers.map((seller) => (
                      <Table.Row key={seller.store_id}>
                        <Table.Cell>{seller.store_id}</Table.Cell>
                        <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                        {/*
                        <Table.Cell>{seller.origen_producto}</Table.Cell>
                        <Table.Cell>{seller.tipo_oferta}</Table.Cell>
                        <Table.Cell>{seller.registro_marca}</Table.Cell>
                        */}
                        <Table.Cell>
                          <a
                            href={seller.url ?? seller.url_tienda}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver tienda
                          </a>
                        </Table.Cell>
                        <Table.Cell>{seller.subscribed_at ?? ''}</Table.Cell>
                        {/*
                        <Table.Cell>{seller.nombre}</Table.Cell>
                        <Table.Cell>{seller.telefono}</Table.Cell>
                        <Table.Cell>{seller.email}</Table.Cell>
                        */}
                        <Table.Cell>
                          <Input
                            id={seller.store_id}
                            name="seller_tag"
                            type="text"
                            placeholder=""
                            onChange={(event) => {
                              handleChangeSellerTag(event);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => viewSellerData(seller.store_id)}
                            appearance="primary"
                          >
                            Ver datos
                          </Button>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => approveSeller(seller.store_id)}
                            appearance="primary"
                          >
                            Aprobar
                          </Button>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => rejectSeller(seller.store_id)}
                            appearance="danger"
                          >
                            Rechazar
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Box display="flex" flexDirection="column" gap="4" padding="4">
                  <Title textAlign="center" as="h3">
                    Lista de sellers aprobados
                  </Title>
                  <Box display="inline-flex" gap="2" maxWidth="200px">
                    <Text as="span">Filtrar: </Text>
                    <Input
                      name="filtro_aprobados"
                      type="text"
                      placeholder=""
                      onChange={(event) => {
                        handleFilterAprobados(event);
                      }}
                    />
                  </Box>
                </Box>
                <Table>
                  <Table.Head>
                    {headersAprobados.map((header) => (
                      <Table.Cell key={header}>{header}</Table.Cell>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {approvedSellers.map((seller) => (
                      <Table.Row key={seller.store_id}>
                        <Table.Cell>{seller.store_id}</Table.Cell>
                        <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                        {/*
                        <Table.Cell>{seller.origen_producto}</Table.Cell>
                        <Table.Cell>{seller.tipo_oferta}</Table.Cell>
                        <Table.Cell>{seller.registro_marca}</Table.Cell>
                        */}
                        <Table.Cell>
                          <a
                            href={seller.url ?? seller.url_tienda}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver tienda
                          </a>
                        </Table.Cell>
                        <Table.Cell>{seller.approved_at ?? ''}</Table.Cell>
                        {/*
                        <Table.Cell>{seller.nombre}</Table.Cell>
                        <Table.Cell>{seller.telefono}</Table.Cell>
                        <Table.Cell>{seller.email}</Table.Cell>
                        */}
                        <Table.Cell>{seller.seller_tag}</Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => viewSellerData(seller.store_id)}
                            appearance="primary"
                          >
                            Ver datos
                          </Button>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => viewSellerProducts(seller.store_id)}
                            appearance="primary"
                          >
                            Ver productos
                          </Button>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => viewSellerMetrics(seller.store_id)}
                            appearance="primary"
                          >
                            Ver metricas
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Layout.Section>
            </Layout>
          </Page.Body>
        </>
      )}
    </Page>
  );
};

export default SetupPage;
