import React, { useEffect, useState } from 'react';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { Layout, Page } from '@nimbus-ds/patterns';
import { Box, Button, Table, Title, useToast } from '@nimbus-ds/components';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';

const RejectedPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [rejectedSellers, setRejectedSellers] = useState<Seller[]>([]);
  const [uninstalledSellers, setUninstalledSellers] = useState<Seller[]>([]);

  interface Seller {
    store_id: string;
    nombre_tienda: string;
    origen_producto: string;
    tipo_oferta: string;
    registro_marca: string;
    nombre: string;
    telefono: string;
    email: string;
    url_tienda: string;
    url: string;
    seller_tag: string;
    status: string;
    rejected_at: string | null;
    uninstalled_at: string | null;
  }

  const headersRechazados = [
    'Id',
    'Nombre Tienda',
    'Url',
    'Fecha rechazado',
    'Nombre',
    'Acciones',
  ];

  const headersDesinstalados = [
    'Id',
    'Nombre Tienda',
    'Url',
    'Fecha desinstalado',
    'Nombre',
    'Acciones',
  ];

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    getListOfRejectedSellers();
    getListOfUninstalledSellers();
  }, []);

  const getListOfRejectedSellers = async () => {
    console.log('obteniendo sellers rechazados...');
    try {
      const response = await axiosInstance.get('/rejected_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setRejectedSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const getListOfUninstalledSellers = async () => {
    console.log('obteniendo sellers desinstalados...');
    try {
      const response = await axiosInstance.get('/uninstalled_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setUninstalledSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const viewSellerData = async (store_id: any) => {
    console.log('view seller data store ' + store_id);
    push({
      pathname: '/data?' + store_id,
      state: { data: store_id },
    });
  };

  const restoreSeller = async (store_id: any) => {
    console.log('restore seller store id : ' + store_id);
    const seller = rejectedSellers.find((s) => s.store_id == store_id);
    if (seller) {
      await axiosInstance
        .post('/restore_seller', {
          store_id: store_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            //const seller = rejectedSellers.find((s) => s.store_id == store_id);
            setRejectedSellers(
              rejectedSellers.filter((seller) => {
                return seller.store_id !== store_id;
              }),
            );
            addToast({
              id: 'restore-seller-success',
              type: 'success',
              text: 'Seller restaurado!',
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          addToast({
            id: 'restore-seller-fail',
            type: 'danger',
            text:
              e.response.data?.message ??
              'Error al tratar de restaurar el seller',
            duration: 4000,
          });
          console.log(e);
        });
    } else {
      addToast({
        id: 'restore-seller-fail-2',
        type: 'danger',
        text: 'El seller no se ha podido restaurar. No se ha encontrado seller en la lista',
        duration: 4000,
      });
    }
    return;
  };

  /*
  const removeProductsUninstalledSeller = async (store_id: any) => {
    console.log('remove product uninstalled seller store id : ' + store_id);
    const seller = uninstalledSellers.find((s) => s.store_id == store_id);
    return;
  };
  */

  return (
    <Page maxWidth="1500px" marginTop="18">
      <Page.Header
        alignItems="center"
        title="Sellers Rechazados/Desinstalados"
      />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Box display="flex" flexDirection="column" gap="4" padding="4">
              <Title textAlign="center" as="h3">
                Sellers rechazados
              </Title>
            </Box>
            <Table>
              <Table.Head>
                {headersRechazados.map((header) => (
                  <Table.Cell key={header}>{header}</Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {rejectedSellers.map((seller) => (
                  <Table.Row key={seller.store_id}>
                    <Table.Cell>{seller.store_id}</Table.Cell>
                    <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                    <Table.Cell>
                      {' '}
                      <a
                        href={seller.url ?? seller.url_tienda}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver tienda
                      </a>
                    </Table.Cell>
                    <Table.Cell>{seller.rejected_at ?? ''}</Table.Cell>
                    <Table.Cell>{seller.nombre}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => viewSellerData(seller.store_id)}
                        appearance="primary"
                      >
                        Ver datos
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => restoreSeller(seller.store_id)}
                        appearance="neutral"
                      >
                        Restaurar
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Box display="flex" flexDirection="column" gap="4" padding="4">
              <Title textAlign="center" as="h3">
                Sellers desinstalados
              </Title>
            </Box>
            <Table>
              <Table.Head>
                {headersDesinstalados.map((header) => (
                  <Table.Cell key={header}>{header}</Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {uninstalledSellers.map((seller) => (
                  <Table.Row key={seller.store_id}>
                    <Table.Cell>{seller.store_id}</Table.Cell>
                    <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                    <Table.Cell>
                      {' '}
                      <a
                        href={seller.url ?? seller.url_tienda}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver tienda
                      </a>
                    </Table.Cell>
                    <Table.Cell>{seller.uninstalled_at ?? ''}</Table.Cell>
                    <Table.Cell>{seller.nombre}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => viewSellerData(seller.store_id)}
                        appearance="primary"
                      >
                        Ver datos
                      </Button>
                    </Table.Cell>
                    {/*
                    <Table.Cell>
                      <Button
                        onClick={() =>
                          removeProductsUninstalledSeller(seller.store_id)
                        }
                        appearance="danger"
                      >
                        Quitar productos
                      </Button>
                    </Table.Cell>
                    */}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default RejectedPage;
function addToast(arg0: {
  id: string;
  type: string;
  text: string;
  duration: number;
}) {
  throw new Error('Function not implemented.');
}
