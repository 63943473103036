import React, { useEffect, useState } from 'react';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { Layout, Page } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Table,
  Title,
  Text,
  useToast,
  Tag,
} from '@nimbus-ds/components';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

const SellerPage: React.FC = () => {
  //const [rejectedSellers, setRejectedSellers] = useState<Seller[]>([]);
  const { addToast } = useToast();
  const location = useLocation();
  const [sellerData, setSellerData] = useState<Seller>();
  const [appStatus, setAppStatus] = useState<string>();
  const [productStatus, setProductStatus] = useState<string>();
  const [productsSync, setProductsSync] = useState<string>();

  interface Seller {
    store_id: string;
    nombre_tienda: string;
    origen_producto: string;
    tipo_oferta: string;
    registro_marca: string;
    nombre: string;
    telefono: string;
    email: string;
    url_tienda: string;
    url: string;
    store_type: string;
    store_created_at: string;
    created_at: string;
    seller_tag: string;
    total_clients: number | null;
    total_orders: number | null;
    total_products: number | null;
    total_orders_sold: number;
    status: string;
  }

  const headers = [
    'Id',
    'Nombre Tienda',
    'Origen producto',
    'Tipo oferta',
    'Registro marca',
    'Url',
    'Nombre',
    'Teléfono',
    'Email',
    'Acciones',
  ];

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  //getListOfRejectedSellers();
  useEffect(() => {
    console.log(location.search);
    if (location.search && location.search.includes('?')) {
      console.log('includes!');
      const store = location.search.replace('?', '');
      console.log('store : ', store);
      if (!isNaN(parseInt(store))) {
        getSellerData(parseInt(store));
        console.log('getting for store ' + store);
      }
    }
  }, []);

  const getSellerData = async (store_id: number) => {
    console.log('get seller data for ', store_id);
    await axiosInstance
      .get('/seller_data', {
        params: {
          store_id: store_id,
        },
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.seller.nombre_tienda);
        setSellerData(response?.data?.seller);
        setAppStatus(response?.data?.app_status);
        setProductStatus(response?.data?.product_status);
        setProductsSync(response?.data?.products_sync);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const removeProductsUninstalledSeller = async (store_id: any) => {
    console.log('remove product uninstalled seller store id : ' + store_id);
    if (store_id) {
      await axiosInstance
        .post('/remove_seller_products', {
          store_id: store_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            addToast({
              id: 'remove-seller-products-success',
              type: 'success',
              text: 'Productos eliminados!',
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          addToast({
            id: 'remove-seller-products-fail',
            type: 'danger',
            text:
              e.response.data?.message ??
              'Error al tratar de remover productos',
            duration: 4000,
          });
          console.log(e);
        });
    } else {
      addToast({
        id: 'remove-seller-products-fail-2',
        type: 'danger',
        text: 'No se han podido borrar los productos. No se ha encontrado seller en la lista',
        duration: 4000,
      });
    }
    return;
  };

  const uninstallAndremoveProductsSeller = async (store_id: any) => {
    console.log('uninstall & remove product seller store id : ' + store_id);
    if (store_id) {
      await axiosInstance
        .post('/uninstall_seller', {
          store_id: store_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            addToast({
              id: 'uninstall-seller-success',
              type: 'success',
              text: 'Seller desinstalado',
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          addToast({
            id: 'uninstall-seller-fail',
            type: 'danger',
            text:
              e.response.data?.message ??
              'Error al tratar de eliminar el seller',
            duration: 4000,
          });
          console.log(e);
        });
    } else {
      addToast({
        id: 'uninstall-seller-fail-2',
        type: 'danger',
        text: 'No se ha podido eliminar el seller. Seller no encontrado.',
        duration: 4000,
      });
    }
    return;
  };

  return (
    <Page maxWidth="1500px" marginTop="18">
      <Page.Header alignItems="center" title="Información de seller" />
      <Page.Body>
        <Card>
          <Layout columns="2 - symmetric">
            <Layout.Section>
              <Box display="flex" flexDirection="column" gap="4" padding="4">
                <Box display="flex" flexDirection="row" gap="2">
                  <Text>
                    <strong>Estado aplicación:</strong>
                  </Text>
                  <Tag appearance="primary">
                    <Text
                      color={
                        appStatus == 'installed'
                          ? 'success-textLow'
                          : 'danger-textLow'
                      }
                    >
                      {appStatus == 'installed'
                        ? 'ACTIVA'
                        : appStatus == 'uninstalled'
                        ? 'INACTIVA'
                        : ''}
                    </Text>
                  </Tag>
                </Box>
                <Text>
                  <strong>Nombre tienda:</strong> {sellerData?.nombre_tienda}
                </Text>
                <Text>
                  <strong>Url tienda:</strong> {sellerData?.url}
                </Text>
                <Text>
                  <strong>Tipo tienda:</strong>{' '}
                  {sellerData?.store_type ?? ' No especificado'}
                </Text>
                <Text>
                  <strong>Tipo oferta:</strong> {sellerData?.tipo_oferta}
                </Text>
                <Text>
                  <strong>Origen producto:</strong>{' '}
                  {sellerData?.origen_producto}
                </Text>
                <Text>
                  <strong>Registro IMPI:</strong> {sellerData?.registro_marca}
                </Text>
                <Text>
                  <strong>Fecha de alta tienda:</strong>{' '}
                  {sellerData?.store_created_at}
                </Text>
                <Text>
                  <strong>Fecha de registro:</strong> {sellerData?.created_at}
                </Text>
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Box display="flex" flexDirection="column" gap="4" padding="4">
                <Text>
                  <strong>Nombre:</strong> {sellerData?.nombre}
                </Text>
                <Text>
                  <strong>Email:</strong> {sellerData?.email}
                </Text>
                <Text>
                  <strong>Teléfono:</strong> {sellerData?.telefono}
                </Text>
                <Text>
                  <strong>Clientes totales al alta:</strong>{' '}
                  {sellerData?.total_clients ?? 0}
                </Text>
                <Text>
                  <strong>Ordenes totales al alta:</strong>{' '}
                  {sellerData?.total_orders ?? 0}
                </Text>
                <Text>
                  <strong>Productos totales al alta:</strong>{' '}
                  {sellerData?.total_products ?? 0}
                </Text>
                <Text>
                  <strong>Ordenes vendidas por marketplace:</strong>{' '}
                  {sellerData?.total_orders_sold ?? 0}
                </Text>
                <Text>
                  <strong>Productos sinc. marketplace:</strong>{' '}
                  {productsSync ?? 0}
                </Text>
              </Box>
            </Layout.Section>
          </Layout>
        </Card>
        {appStatus == 'uninstalled' && productStatus == 'active' ? (
          <Card>
            <Card.Header>
              <Title fontSize="4">Remover productos de tienda</Title>
            </Card.Header>
            <Card.Body>
              <Box>
                {' '}
                <Button
                  onClick={() =>
                    removeProductsUninstalledSeller(sellerData?.store_id)
                  }
                  appearance="danger"
                >
                  Remover productos: {productsSync ?? 'indefinido'}
                </Button>
              </Box>
            </Card.Body>
          </Card>
        ) : (
          <>
            {appStatus == 'installed' && productStatus == 'active' ? (
              <>
                <Card>
                  <Card.Header>
                    <Title fontSize="4">
                      Desinstalar tienda y remover productos
                    </Title>
                  </Card.Header>
                  <Card.Body>
                    <Box>
                      {' '}
                      <Button
                        onClick={() =>
                          uninstallAndremoveProductsSeller(sellerData?.store_id)
                        }
                        appearance="danger"
                      >
                        Desinstalar y remover productos:{' '}
                        {productsSync ?? 'indefinido'}
                      </Button>
                    </Box>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <div></div>
            )}
          </>
        )}
      </Page.Body>
    </Page>
  );
};

export default SellerPage;
