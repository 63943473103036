/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import nexo from '../../nexoClient';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Tag,
  Text,
  Title,
  Modal,
  IconButton,
  Icon,
  Link,
  Tooltip,
  Table,
  Input,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axiosInstance from '../../axios';
import { useWindowWidth } from '../../hooks';
import { BoxPackedIcon, CogIcon, QuestionCircleIcon } from '@nimbus-ds/icons';

const ConfigPage: React.FC = () => {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');
  const { addToast } = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [productFilter, setProductFilter] = useState<string>();
  const [productNameFilter, setProductNameFilter] = useState<string>();
  const [currentSeller, setCurrentSeller] = useState<Seller>();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [allproducts, setAllProducts] = useState<Product[]>([]);
  const [marketPlaceCategories, setMarketPlaceCategories] = useState<
    Category[]
  >([]);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const goToLogs = () => push('/logs');
  const goToSetup = () => push('/setup');

  const location = useLocation();

  /*
  useEffect(() => {
    console.log(location);
    console.log(location.pathname); // result: '/secondpage'
    console.log(location.search); // result: '?query=abc'
  }, [location]);
  */

  interface Seller {
    store_id: number;
    nombre_tienda: string;
    url: string;
  }

  interface Product {
    store_id: number;
    tn_id: number;
    name: string;
    handle: string;
    active: boolean;
    vinculated_category_id: number;
    vinculated_category_name: string;
    sales_count: number | null;
    total_sales_count: number | null;
    sync: number;
  }

  interface Category {
    id: number;
    name: string;
  }

  useEffect(() => {
    setProductFilter('Pendientes');
    getSellersList();
    getMarketPlaceCategories();
    console.log(location.search);
    if (location.search && location.search.includes('?')) {
      console.log('includes!');
      const store = location.search.replace('?', '');
      console.log('store : ', store);
      if (!isNaN(parseInt(store))) {
        handleInitSellerProducts(parseInt(store));
      } else {
        console.log('is nan');
      }
    }
  }, []);

  const handleInitSellerProducts = async (store_id: number) => {
    setTimeout(() => {
      console.log('handle init seller products');
      for (const seller of sellers) {
        console.log(seller.store_id, ' ', store_id);
        if (seller.store_id == store_id) {
          console.log('seller found');
          const currentSeller: Seller = {
            store_id: seller.store_id,
            nombre_tienda: seller.nombre_tienda,
            url: seller.url,
          };
          setProductNameFilter('');
          setCurrentSeller(currentSeller);
          getSellerProducts(seller.store_id);
          break;
        }
      }
    }, 2000);
  };

  const handleChangeProductsView = async (e: any) => {
    console.log(e.target.value);
    setProductFilter(e.target.value);
  };

  const handleFilterProductName = async (e: any) => {
    setProductNameFilter(e.target.value);
    const filteredProducts = [];
    if (products && e.target.value != '') {
      for (const prod of allproducts) {
        if (prod.name.includes(e.target.value)) {
          filteredProducts.push(prod);
        }
      }
      setProducts(filteredProducts);
    } else if (products && e.target.value == '') {
      setProducts(allproducts);
    }
  };

  const getMarketPlaceCategories = async () => {
    await axiosInstance
      .get('/marketplace_categories')
      .then((response) => {
        setMarketPlaceCategories(response?.data?.categories);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const headers = [
    'Id',
    'Nombre',
    'Link producto',
    'Estado',
    'Ventas',
    'Ventas unitarias',
    'Categoria',
    'Acción',
  ];

  const getSellersList = async () => {
    console.log('get sellers list');

    await axiosInstance
      .get('/approved_sellers_list')
      .then((response) => {
        setSellers(response?.data?.sellers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeSellerSelect = async (e: any) => {
    console.log('handle change seller list');
    console.log(e.target.value);
    console.log(e.target.label);
    for await (const seller of sellers) {
      if (seller.store_id == e.target.value) {
        console.log('seller found');
        const currentSeller: Seller = {
          store_id: seller.store_id,
          nombre_tienda: seller.nombre_tienda,
          url: seller.url,
        };
        setProductNameFilter('');
        setCurrentSeller(currentSeller);
        getSellerProducts(seller.store_id);
        break;
      }
    }
  };

  const getSellerProducts = async (store_id: number) => {
    console.log('get seller products for ', store_id);
    await axiosInstance
      .get('/seller_products', {
        params: {
          store_id: store_id,
        },
      })
      .then((response) => {
        console.log(response.data);
        setProducts(response.data.products);
        setAllProducts(response.data.products);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeVinculateProductCategory = async (e: any) => {
    console.log(e.target.id);
    console.log(e.target.value);

    const target_product = products.find((p) => p.tn_id == e.target.id);

    if (target_product) {
      const target_category = marketPlaceCategories.find(
        (c) => c.name == e.target.value,
      );
      if (target_category) {
        console.log('target category id', target_category.id);
        target_product.vinculated_category_id = target_category.id;
        target_product.vinculated_category_name = target_category.name;
        addToast({
          id: 'category-change-success-toast',
          type: 'success',
          text: 'categoria asignada',
          duration: 4000,
        });
      } else {
        console.log('ERROR TARGET CATEGORY NOT FOUND');
        addToast({
          id: 'category-change-error-toast-1',
          type: 'danger',
          text: 'categoria no encontrada',
          duration: 4000,
        });
      }
    } else {
      console.log('ERROR TARGET PRODUCT NOT FOUND');
      addToast({
        id: 'category-change-error-toast-2',
        type: 'danger',
        text: 'producto no encontrado',
        duration: 4000,
      });
    }
  };

  const approveProduct = async (product_id: number) => {
    console.log('approve product with id ', product_id);

    const target_product = products.find((p) => p.tn_id == product_id);

    if (target_product) {
      if (
        target_product.vinculated_category_id &&
        target_product.vinculated_category_name
      ) {
        setButtonDisabled(true);
        await axiosInstance
          .post('/approve_product', {
            store_id: target_product.store_id,
            product_id: target_product.tn_id,
            category_id: target_product.vinculated_category_id,
          })
          .then((response) => {
            setButtonDisabled(false);
            if (response.data.status == true) {
              addToast({
                id: 'approve-product-toast-success',
                type: 'success',
                text: 'Vinculado producto... podrás ver el resultado en registros',
                duration: 4000,
              });
              //remove product for list
              const f_products = products.filter(
                (p) => p.tn_id != target_product.tn_id,
              );
              console.log(f_products);
              setProducts(f_products);
              setAllProducts(f_products);
            }
          })
          .catch((e) => {
            setButtonDisabled(false);
            addToast({
              id: 'approve-product-toast-error',
              type: 'danger',
              text:
                e.response.data?.message ?? 'No se pudo aprobar el producto',
              duration: 4000,
            });
            console.log(e);
          });
      } else {
        console.log('ERROR TARGET PRODUCT CATEGORY DATA NOT FOUND');
        addToast({
          id: 'approved-product-error-toast-1',
          type: 'danger',
          text: 'datos de categoria de producto no encontrados',
          duration: 4000,
        });
      }
    } else {
      console.log('ERROR TARGET PRODUCT NOT FOUND');
      addToast({
        id: 'approved-product-error-toast-2',
        type: 'danger',
        text: 'producto no encontrado',
        duration: 4000,
      });
    }
  };

  const refreshList = async () => {
    console.log('TODO: Refresh list');
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        source={<QuestionCircleIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button appearance="primary" disabled={true} onClick={refreshList}>
          REFRESCAR
        </Button>
      </Box>
    );

  return (
    <Page maxWidth="1300px">
      <Page.Header title="Productos" buttonStack={actions} />
      <Page.Body>
        <Card>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            marginBottom="6"
          >
            <Text>Seleccionar Seller</Text>
            <Select
              appearance="neutral"
              id="seller-list-select"
              name="Name"
              onChange={(event) => {
                handleChangeSellerSelect(event);
              }}
            >
              <Select.Option
                disabled
                label="Seleccionar Seller"
                selected={currentSeller == null}
                value="Seleccionar Seller"
              />
              {sellers ? (
                sellers.map((seller: Seller) => (
                  <Select.Option
                    key={seller.store_id}
                    value={String(seller.store_id)}
                    label={seller.nombre_tienda}
                  ></Select.Option>
                ))
              ) : (
                <Select.Skeleton />
              )}
            </Select>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            marginBottom="6"
          >
            <Text>Mostrar productos</Text>
            <Select
              appearance="neutral"
              id="seller-list-select"
              name="Name"
              onChange={(event) => {
                handleChangeProductsView(event);
              }}
            >
              <Select.Option label="Pendientes" value="Pendientes" />
              <Select.Option label="Aprobados" value="Aprobados" />
              <Select.Option label="Todos" value="Todos" />
            </Select>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            maxWidth="300px"
          >
            <Text>Filtro nombre</Text>
            <Input
              appearance="neutral"
              id="seller-filter-name"
              name="FilterName"
              onChange={(event) => {
                handleFilterProductName(event);
              }}
            />
          </Box>
        </Card>
        <Card>
          <Title fontSize="4">Listado de productos</Title>
          <Table>
            <Table.Head>
              {headers.map((header) => (
                <Table.Cell key={header}>{header}</Table.Cell>
              ))}
            </Table.Head>
            <Table.Body>
              {products.map((product) => (
                <Table.Row
                  key={product.tn_id}
                  hidden={
                    (productFilter == 'Pendientes' && product.sync == 1) ||
                    (productFilter == 'Aprobados' && product.sync == 0)
                  }
                >
                  <Table.Cell>{product.tn_id}</Table.Cell>
                  <Table.Cell>{product.name}</Table.Cell>
                  <Table.Cell>
                    {currentSeller ? (
                      <a
                        href={
                          currentSeller.url + '/productos/' + product.handle
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver producto
                      </a>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {product.active == true ? 'activo' : 'inactivo'}
                  </Table.Cell>
                  <Table.Cell>{product.sales_count ?? 0}</Table.Cell>
                  <Table.Cell>{product.total_sales_count ?? 0}</Table.Cell>
                  <Table.Cell>
                    {product.sync == 0 ? (
                      <Select
                        appearance="neutral"
                        id={String(product.tn_id)}
                        name="Name"
                        onChange={(event) => {
                          handleChangeVinculateProductCategory(event);
                        }}
                      >
                        <Select.Option
                          disabled={product.vinculated_category_id != null}
                          label="Seleccionar categoria"
                          selected={product.vinculated_category_id == null}
                          value="Seleccionar categoria"
                        />
                        {marketPlaceCategories ? (
                          marketPlaceCategories.map((category: Category) => (
                            <Select.Option
                              key={product.store_id + '%' + category.id}
                              value={category.name}
                              label={category.name}
                            ></Select.Option>
                          ))
                        ) : (
                          <Select.Skeleton />
                        )}
                      </Select>
                    ) : (
                      <Tag appearance="success">
                        <BoxPackedIcon size={12} />
                        <Text color="success-textLow">
                          {product.vinculated_category_name}
                        </Text>
                      </Tag>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {product.sync == 0 ? (
                      <Button
                        onClick={() => approveProduct(product.tn_id)}
                        appearance="primary"
                        disabled={isButtonDisabled}
                      >
                        Aprobar
                      </Button>
                    ) : (
                      <Tag appearance="success">
                        <BoxPackedIcon size={12} />
                        <Text color="success-textLow">Aprobado</Text>
                      </Tag>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </Page.Body>
    </Page>
  );
};

export default ConfigPage;
